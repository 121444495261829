import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { noop } from "lodash";
import React, { useState, useTransition } from "react";
import {
  Bar,
  ComposedChart as BarChart,
  CartesianGrid,
  Customized,
  Line,
  Rectangle,
  Tooltip,
  XAxis,
  YAxis,
  Legend as _Legend,
} from "recharts";
import { fiscalGranularities } from "../../analytics/fiscalDateUtils";
import {
  DEFAULT_CHART_GROUPINGS_LIMIT,
  UnitType,
} from "../../constants/analytics";
import { ChartType, Operator, TimeGranularity } from "../../constants/enums";
import { ActivityTracker, actions } from "../../telemetry";
import Box from "../components/Box";
import EmptyPlaceholder from "../components/EmptyPlaceholder";
import copyText from "../copyText";
import {
  DATA_VIZ_COLORS_LOW_OPACITY,
  ECO_DATA_VIZ_COLORS,
} from "../theme/default";
import { useChartDataManager } from "../utils/ChartDataManager";
import { getFormatForGranularity } from "../utils/dates";
import { ChartWrapper } from "./ChartWrapper";
import ExperimentalTooltip from "./ExperimentalTooltip";
import Legend from "./Legend";
import LegendSimple from "./LegendSimple";
import LegendTable from "./LegendTable";
import {
  barStyleProps,
  cartesianStyleProps,
  lineStyleProps,
  xAxisStyleProps,
  yAxisStyleProps,
} from "./styles";
import { getTooltip } from "./TooltipTable";
import { useHorizontalLine, useTooltipCollector } from "./TooltipUtils";
import {
  ChartDatum,
  Dimension,
  Filter,
  Measure,
  RawData,
  ReadableKeys,
} from "./types";
import {
  DEFAULT_X_AXIS_KEY,
  FORECASTED_KEY,
  NOT_SHOWN_KEY,
  PERCENTAGE_Y_AXIS_ID,
  REPORT_PDF_CHART_HEIGHT,
  REPORT_PDF_CHART_WIDTH,
  formatMeasureValueWithUnit,
  formatTimestamp,
  getColorByReverseIndex,
  getGreenColors,
  getIsDashedMeasure,
  getTicks,
  getUniformUnitType,
} from "./utils";
import YAxisTick from "./YAxisTick";

interface Props {
  activityTracker?: ActivityTracker;
  clustered?: boolean;
  currencyCode?: string;
  data: RawData[];
  dimensions: Dimension[];
  disableDrilldown?: boolean;
  emptyPlaceholderText?: string;
  excludeOther?: boolean;
  hideSubtotal?: boolean;
  hideTotal?: boolean;
  isComparisonMode?: boolean;
  isDataSorted?: boolean;
  isEcoImpactMode?: boolean;
  isFiscalMode?: boolean;
  isForecastingMode?: boolean;
  isLoading: boolean;
  isServer?: boolean;
  limit?: number | null;
  maxGroupings?: number;
  measures: Measure[];
  readableKeys?: ReadableKeys;
  showLegend?: boolean;
  showExperimentalTooltip?: boolean;
  showTooltip?: boolean;
  timeSeriesGranularity: TimeGranularity;
  tooltipLimit?: number;
  xAxisKey?: string;
  tooltipFormatter?: (value: any, grouping: string) => string;
  xAxisFormatter?: (value: any) => string;
  onInteraction?: (interaction: StackedBarChart.Interaction) => void;
}

const StyledBox = styled(Box)`
  .recharts-legend-wrapper {
    max-height: 8rem;
    overflow-y: auto;
  }

  .recharts-legend-item {
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: ${({ theme }) => theme.space_xs};

    .recharts-symbols {
      d: path("M -8 -16 h 32 v 32 h -32 Z");
    }
  }

  span.recharts-legend-item-text {
    color: ${(props) => props.theme.text_color} !important;
    display: block;
    font-size: ${({ theme }) => theme.fontSize_ui};
    /* Bring in the below if legend key lengths become an issue */
    /* max-width: 40rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }

  .recharts-default-legend {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;

    li {
      cursor: pointer;
    }
  }
`;

const AXIS_CHARACTER_WIDTH = 7;
const AXIS_MAX_LEFT_MARGIN = AXIS_CHARACTER_WIDTH * 10;
const DEFAULT_MAX_GROUPINGS_TO_DISPLAY = DEFAULT_CHART_GROUPINGS_LIMIT + 1; // The rest will go into the OTHER category to maintain an accurate stack total

const STACK_ID = "STACK_ID";
const DEFAULT_Y_AXIS_ID = "DEFAULT_Y_AXIS_ID";

export function StackedBarChart(props: Props): JSX.Element {
  const theme = useTheme();

  const MAX_GROUPINGS_TO_DISPLAY = props.maxGroupings
    ? props.maxGroupings
    : props.limit
      ? props.limit
      : DEFAULT_MAX_GROUPINGS_TO_DISPLAY;

  const xAxisKey = props.xAxisKey ?? DEFAULT_X_AXIS_KEY;
  let clusterLayouts: null | ClusterLayout[] = null;

  const [isPending, startTransition] = useTransition();

  const [excludedKeys, setExcludedKeys] = useState<string[]>([]);
  const [tooltipDataKey, setTooltipDataKey] = useState<string | undefined>(
    undefined
  );

  //
  // Custom Elements
  //

  const horizontalLineElement = useHorizontalLine();
  const [tooltipCollectorElement, getCursorValue] =
    useTooltipCollector(DEFAULT_Y_AXIS_ID);

  //
  // Data
  //

  const dataManager = useChartDataManager({
    data: props.data,
    dimensions: props.dimensions,
    excludedChartKeys: excludedKeys,
    excludeOther: props.excludeOther,
    isDataSorted: props.isDataSorted,
    maxGroupingCount: MAX_GROUPINGS_TO_DISPLAY,
    measures: props.measures,
    xAxisKey,
  });

  const isImpactMode = props.isEcoImpactMode ?? false;

  const uniformBarMeasureUnit = getUniformUnitType(props.measures);

  const dynamicAxisColors = getGreenColors(isImpactMode, theme);
  const chartColors = isImpactMode
    ? ECO_DATA_VIZ_COLORS
    : theme.data_visualization_colors;

  //
  // Handlers
  //

  function handleClickBar(chartKey: string) {
    const dimensionsWithValues =
      dataManager.getDimensionValuesFromChartKey(chartKey);
    const measure = dataManager.getMeasure(chartKey);

    if (
      !props.onInteraction ||
      !measure ||
      (dataManager.dimensions.length === 0 && dataManager.measures.length === 1)
    ) {
      return;
    }

    if (
      dataManager.dimensions.length === 0 &&
      dataManager.measures.length > 1
    ) {
      props.onInteraction({
        type: StackedBarChart.INTERACTION_MEASURE_CLICKED,
        measure,
      });
      return;
    }

    props.onInteraction({
      type: StackedBarChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED,
      filters: dimensionsWithValues.map(({ dimension, value }) => ({
        name: dimension.name,
        operator: Operator.EQUALS,
        values: [value],
      })),
    });
  }

  function handleInteraction(
    interaction: LegendSimple.Interaction | LegendTable.Interaction
  ) {
    switch (interaction.type) {
      case LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED:
      case LegendTable.INTERACTION_CHART_EXCLUSION_CHANGED: {
        if (props.activityTracker) {
          props.activityTracker.captureAction(
            actions.TOGGLE_CHART_LEGEND_ITEM,
            { keys: interaction.excludedChartKeys }
          );
        }

        setExcludedKeys(interaction.excludedChartKeys);
        break;
      }
      default:
        break;
    }
  }

  //
  // JSX
  //

  if (props.isLoading || props.data.length === 0) {
    return (
      <EmptyPlaceholder
        loading={props.isLoading}
        icon={faChartBar}
        skeletonVariant="cartesian"
        text={
          props.emptyPlaceholderText
            ? props.emptyPlaceholderText
            : copyText.chartEmptyPlaceholderText
        }
      />
    );
  }

  let leftMargin =
    AXIS_CHARACTER_WIDTH *
    Math.round(dataManager.maxValue ?? 10).toString().length;
  if (leftMargin > AXIS_MAX_LEFT_MARGIN) {
    leftMargin = AXIS_MAX_LEFT_MARGIN;
  }

  const dateFormat = getFormatForGranularity(props.timeSeriesGranularity);

  function getColor(params: {
    excluded: boolean;
    key: string;
    index: number;
  }): string {
    if (params.excluded) {
      return theme.text_color_disabled;
    }

    const newIndex = dataManager.sortedChartKeys.indexOf(
      params.key.replace(FORECASTED_KEY, "")
    );

    if (props.isForecastingMode) {
      return getColorByReverseIndex(
        dataManager.sortedChartKeys.length,
        newIndex === -1 ? params.index : newIndex,
        params.key.includes(FORECASTED_KEY)
          ? DATA_VIZ_COLORS_LOW_OPACITY
          : chartColors
      );
    }

    return getColorByReverseIndex(
      dataManager.sortedChartKeys.length,
      params.index,
      chartColors
    );
  }

  const xAxisFormatter = (value: string) =>
    props.xAxisFormatter
      ? props.xAxisFormatter(value)
      : xAxisKey === DEFAULT_X_AXIS_KEY &&
          (!props.isFiscalMode ||
            !fiscalGranularities.includes(props.timeSeriesGranularity))
        ? formatTimestamp(value, dateFormat)
        : value;

  return (
    <StyledBox
      backgroundColor={theme.panel_backgroundColor}
      height="100%"
      position="relative"
      width="100%"
    >
      <ChartWrapper isServer={props.isServer}>
        <BarChart
          barCategoryGap={2}
          barGap={0}
          data={dataManager.chartData}
          height={props.isServer ? REPORT_PDF_CHART_HEIGHT : undefined}
          margin={{
            top: props.isServer ? 10 : 0,
            left: leftMargin,
            right: 25,
          }}
          width={props.isServer ? REPORT_PDF_CHART_WIDTH : undefined}
        >
          {tooltipCollectorElement}
          <CartesianGrid
            {...cartesianStyleProps}
            stroke={theme.chart_cartesian_grid_lines}
          />
          <XAxis
            domain={[0, "auto"]}
            {...xAxisStyleProps}
            stroke={dynamicAxisColors.axis}
            tick={{
              stroke: theme.chart_axis_text,
              fontWeight: 10,
              fontSize: "0.8rem",
            }}
            dataKey={xAxisKey}
            ticks={
              props.isServer
                ? getTicks(dataManager.chartData, xAxisKey)
                : undefined
            }
            tickFormatter={xAxisFormatter}
          />
          <YAxis
            domain={[0, "auto"]}
            {...yAxisStyleProps}
            stroke={dynamicAxisColors.axis}
            tick={<YAxisTick />}
            tickCount={8}
            tickFormatter={(value) =>
              formatMeasureValueWithUnit({
                currencyCode: props.currencyCode,
                unit: uniformBarMeasureUnit,
                value,
              })
            }
            yAxisId={DEFAULT_Y_AXIS_ID}
          />
          <YAxis domain={[0, 100]} hide yAxisId={PERCENTAGE_Y_AXIS_ID} />

          <Customized
            component={(internalState: unknown) => {
              clusterLayouts = generateClusterLayouts({
                allKeys: dataManager.sortedChartKeys,
                barChartInternalState: internalState as BarChartInternalState,
                clusters: dataManager.chartData,
                maxBarWidth: 50,
              });

              return null;
            }}
          />

          {[...dataManager.sortedChartKeys]
            .map(
              (key, index) =>
                [
                  key,
                  index,
                  getIsDashedMeasure(dataManager.getMeasure(key)?.name),
                ] as const
            )
            .sort(([_a, aIndex, isADashed], [_b, bIndex, isBDashed]) => {
              // ensure dashed lines are always drawn after (in front of) areas

              if (isADashed === isBDashed) {
                // preserve order if both dashed / not-dashed
                return Math.sign(aIndex - bIndex);
              }
              if (isADashed) return 1;
              return -1;
            })
            .map(([key, i, isDashedMeasure]) => {
              const excluded = excludedKeys.includes(key);
              const color = getColor({ excluded, key, index: i });

              const measure = dataManager.getMeasure(key);
              const isPercentage = measure?.unit === UnitType.PERCENTAGE;

              if (isDashedMeasure) {
                return (
                  <Line
                    {...lineStyleProps}
                    activeDot={{ style: { cursor: "not-allowed" } }}
                    key={key}
                    dataKey={key}
                    stroke={color}
                    strokeDasharray={"5 5"}
                    {...(excluded ? { strokeWidth: 0 } : { strokeWidth: 2.5 })}
                    yAxisId={
                      isPercentage ? PERCENTAGE_Y_AXIS_ID : DEFAULT_Y_AXIS_ID
                    }
                    style={{
                      cursor: "not-allowed",
                    }}
                    onMouseOver={() =>
                      startTransition(() => setTooltipDataKey(key))
                    }
                    onMouseLeave={() =>
                      startTransition(() => setTooltipDataKey(undefined))
                    }
                  />
                );
              }

              return (
                <Bar
                  {...barStyleProps}
                  key={key}
                  dataKey={key}
                  fill={color}
                  stackId={props.clustered ? undefined : STACK_ID}
                  fillOpacity={theme.chart_fill_opacity}
                  shape={
                    props.clustered
                      ? (rectProps: any) =>
                          transformBarForCluster({
                            barKey: key,
                            clusterLayouts,
                            rectProps,
                          })
                      : undefined
                  }
                  style={{
                    cursor:
                      props.disableDrilldown || key.includes(NOT_SHOWN_KEY)
                        ? "not-allowed"
                        : props.dimensions.length > 0 ||
                            props.measures.length > 0
                          ? "pointer"
                          : "normal",
                  }}
                  yAxisId={DEFAULT_Y_AXIS_ID}
                  onClick={
                    props.disableDrilldown || key.includes(NOT_SHOWN_KEY)
                      ? noop
                      : () => handleClickBar(key)
                  }
                  onMouseOver={() =>
                    startTransition(() => setTooltipDataKey(key))
                  }
                  onMouseLeave={() =>
                    startTransition(() => setTooltipDataKey(undefined))
                  }
                  {...(excluded ? { strokeWidth: 0 } : {})}
                />
              );
            })}

          {horizontalLineElement}
          {props.showTooltip &&
            !props.showExperimentalTooltip &&
            getTooltip(
              {
                dataManager: dataManager,
                hideSubtotal: props.hideSubtotal,
                hideTotal: props.hideTotal,
                hoveredChartKey: isPending ? null : (tooltipDataKey ?? null),
                isImpactMode: isImpactMode,
                maxRows: props.tooltipLimit,
                readableKeys: props.readableKeys,
                xAxisFormatter: xAxisFormatter,
              },
              theme
            )}
          {props.showLegend && (
            <_Legend
              verticalAlign="bottom"
              wrapperStyle={
                props.isServer
                  ? { overflow: "visible", position: "relative" }
                  : undefined
              }
              content={
                <Legend
                  dataManager={dataManager}
                  isServer={props.isServer}
                  readableKeys={props.readableKeys}
                  onInteraction={handleInteraction}
                />
              }
            />
          )}
          {props.showExperimentalTooltip && (
            <Tooltip
              content={
                <ExperimentalTooltip
                  chartType={
                    props.clustered
                      ? ChartType.CLUSTERED_BAR
                      : ChartType.STACKED_BAR
                  }
                  dataManager={dataManager}
                  getCursorValue={getCursorValue}
                  granularity={
                    props.xAxisKey !== DEFAULT_X_AXIS_KEY
                      ? undefined
                      : props.timeSeriesGranularity
                  }
                  readableKeys={props.readableKeys}
                  setTooltipDataKey={(dataKey) =>
                    startTransition(() => setTooltipDataKey(dataKey))
                  }
                  tooltipDataKey={tooltipDataKey}
                  xAxisFormatter={xAxisFormatter}
                />
              }
              cursor={false}
              offset={0}
            />
          )}
        </BarChart>
      </ChartWrapper>
    </StyledBox>
  );
}

type BarChartInternalState = {
  barCategoryGap: number;
  barGap: number;
  xAxisMap: {
    0: {
      bandSize: number;
      padding: { left: number };
      x: number;
    };
  };
  yAxisMap: {
    [yAxisId: string]:
      | {
          height: number;
          niceTicks?: number[];
        }
      | undefined;
  };
};

type ClusterLayout = {
  [barKey: string]: {
    centerX: number;
    width: number;
    x: number;
  };
};

/*
Get info for updated clusters with empty bars removed and
  remaining bars shifted/expanded to fill cluster evenly.
*/
function generateClusterLayouts(params: {
  allKeys: string[];
  barChartInternalState: BarChartInternalState;
  clusters: ChartDatum[];
  maxBarWidth: number;
}): ClusterLayout[] {
  const barCategoryGap = params.barChartInternalState.barCategoryGap;
  const barGap = params.barChartInternalState.barGap;

  const xAxis = params.barChartInternalState.xAxisMap[0];
  const yAxis = params.barChartInternalState.yAxisMap[DEFAULT_Y_AXIS_ID];

  if (!yAxis || !yAxis.niceTicks) {
    return [];
  }

  const clusterWidth = xAxis.bandSize;
  const chartX = xAxis.x;
  const chartPadLeft = xAxis.padding.left;
  const yTickValues = yAxis.niceTicks;
  const yHeight = yAxis.height;

  const leftOffset = chartX + chartPadLeft;
  const minYValue = yTickValues[0];
  const maxYValue = yTickValues.slice(-1)[0];
  const unitsPerYPixel = (maxYValue - minYValue) / yHeight;

  let smallestWidth: number | null = null;

  const clusterLayouts: ClusterLayout[] = [];

  params.clusters.forEach((cluster, clusterIndex) => {
    const barKeysInCluster = params.allKeys.filter((barKey) => {
      const value = cluster[barKey];

      if (typeof value !== "number") {
        // Bar doesn't exist in this cluster
        return false;
      }

      if (Math.abs(value) < unitsPerYPixel) {
        // Bar is less than 1 pixel tall
        return false;
      }

      return true;
    });

    const clusterLayout: ClusterLayout = {};

    barKeysInCluster.forEach((barKey, barIndex) => {
      const barCountInCluster = barKeysInCluster.length;

      const usableClusterWidth = clusterWidth - barCategoryGap * 2;
      const sumOfBarWidths =
        usableClusterWidth - barGap * (barCountInCluster - 1);
      const usableBarWidth = sumOfBarWidths / barCountInCluster;
      const reverseBarIndex = barCountInCluster - (barIndex + 1);

      const centerX =
        // padding for y axis
        leftOffset +
        // all clusters to the left of this
        clusterWidth * clusterIndex +
        // prev bars in this cluster
        (usableClusterWidth / barCountInCluster) * reverseBarIndex +
        // space before prev bar in cluster
        barCategoryGap +
        // half of this bar
        usableBarWidth / 2;

      const width = Math.min(params.maxBarWidth, usableBarWidth);

      if (smallestWidth === null || width < smallestWidth) {
        smallestWidth = width;
      }

      clusterLayout[barKey] = {
        centerX,
        width,
        x: -1,
      };
    });

    clusterLayouts.push(clusterLayout);
  });

  // Make all bars as wide as the smallest bar
  clusterLayouts.forEach((clusterLayout) => {
    Object.keys(clusterLayout).forEach((barKey) => {
      const barLayout = clusterLayout[barKey];

      if (smallestWidth !== null) {
        barLayout.width = smallestWidth;
      }
      barLayout.x = barLayout.centerX - barLayout.width / 2;
    });
  });

  return clusterLayouts;
}

function transformBarForCluster(params: {
  clusterLayouts: ClusterLayout[] | null;
  barKey: string;
  rectProps: any;
}) {
  if (!params.clusterLayouts) {
    return <Rectangle {...params.rectProps} />;
  }

  const clusterIndex = params.rectProps.index as number;
  const barLayout = params.clusterLayouts[clusterIndex]?.[params.barKey];

  if (!barLayout) {
    // render a hidden bar if key not found in cluster
    return <Rectangle style={{ display: "none" }} />;
  }

  const { width, x } = barLayout;

  return <Rectangle {...params.rectProps} x={x} width={width} />;
}

StackedBarChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED =
  `StackedBarChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED` as const;

StackedBarChart.INTERACTION_MEASURE_CLICKED =
  `StackedBarChart.INTERACTION_MEASURE_CLICKED` as const;

interface InteractionAddGroupingFilterClicked {
  type: typeof StackedBarChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED;
  filters: Filter[];
}

interface InteractionMeasureClicked {
  type: typeof StackedBarChart.INTERACTION_MEASURE_CLICKED;
  measure: Measure;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace StackedBarChart {
  export type Interaction =
    | InteractionAddGroupingFilterClicked
    | InteractionMeasureClicked;
}

export default StackedBarChart;
